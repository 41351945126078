import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function HomePage() {
  
  const navigate = useNavigate();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Loop over the entries
        entries.forEach((entry) => {
          // If the element is visible
          if (entry.isIntersecting) {
            // Add the animation class
            entry.target.classList.add("animate");
          }
        });
      },
      {
        threshold: 1,
      }
    );

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
        }
      });
    };

    const observer2 = new IntersectionObserver(observerCallback, {
      rootMargin: "0px",
      threshold: 1,
    });

    // Register observer for elements with the scroll-ani class
    const registerObserver2 = () => {
      try {
        const targets = document.querySelectorAll(".scroll-ani");
        targets.forEach((target) => {
          observer2.observe(target);
        });
      } catch (error) {
        // console.log("Observer2 error:", error);
      }
    };

    window.addEventListener("load", registerObserver2);

    // Clean up observer and event listener on component unmount
    return () => {
      window.removeEventListener("load", registerObserver2);
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <div className="globalWrapper">
      <header id="site-header">
        <div id="site-header-inner" className="clr container">
          <div id="site-logo" className="clr" itemScope itemType="https://schema.org/Brand">
            <div id="site-logo-inner" className="clr">
              <a href="https://www.egain.com/" className="custom-logo-link" target="_blank" rel="noopener noreferrer">
                <img
                  width="140"
                  src="https://www.egain.com/egain-media/2022/10/eGain-logo-new-300.png"
                  className="custom-logo"
                  alt="eGain"
                  decoding="async"
                  fetchPriority="high"
                />
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div id="p-header" className="container mainWrapper">
          <div id="h1-con">
            <div className="overflow-hidden" style={{ height: '34px' }}>
              <span style={{ transform: 'translateY(100%)', display: 'block', zIndex: 2, position: 'relative' }}>
                <p className="eg-tagline">CONNECTING THE DOTS</p>
              </span>
            </div>
            <h1 className="egainKB">
              <div className="overflow-hidden">
                <span style={{ transform: 'translateY(100%)', display: 'block' }}>
                  <strong className="strongText">eGain</strong>
                </span>
              </div>
              <div className="overflow-hidden">
                <span style={{ transform: 'translateY(100%)', display: 'block' }}>
                  <strong className="strongText">Knowledge Hub</strong>
                </span>
              </div>
            </h1>
            <h3>
              <div className="overflow-hidden">
                <span style={{ transform: 'translateY(100%)', display: 'block' }}>
                  TRIAL & SUBSCRIPTION
                </span>
              </div>
            </h3>

            <div className="overflow-hidden" style={{ height: '64px', zIndex: 2, position: 'relative', display: 'inline-block' }}>
              <span className="btn-span scroll-ani" style={{ transform: 'translateY(140%)', display: 'inline-block' }}>
                <a href="" onClick={() => navigate('/try')} className="eg-btn-black scroll-ani buttonLink">Try now</a>
              </span>
            </div>

            <div className="overflow-hidden" style={{ height: '64px', zIndex: 2, position: 'relative', display: 'inline-block', marginLeft: '15px' }}>
              <span id="trybuy-btn" className="btn-span scroll-ani" style={{ transform: 'translateY(140%)', display: 'inline-block' }}>
                <a href="" onClick={() => navigate('/buy')} className="eg-btn-black scroll-ani buttonLink">Buy now</a>
              </span>
            </div>

            <div className="overflow-hidden" style={{ height: '64px', zIndex: 2, position: 'relative', display: 'inline-block', marginLeft: '15px' }}>
              <span className="btn-span scroll-ani" style={{ transform: 'translateY(140%)', display: 'inline-block' }}>
                <a href="" onClick={() => navigate('/login')} className="eg-btn-black scroll-ani buttonLink">Log in</a>
              </span>
            </div>
          </div>

          <div id="h-img">
            <div id="h-img-con"></div>
          </div>
        </div>
      </main>

      <footer className="container">
        <p>Copyright © 2024. eGain Corporation. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;
