import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import EntryHeader from 'src/components/entryPage/EntryHeader';
import journeySlice from 'src/components/journey/journeySlice';
import Sidebar from 'src/components/entryPage/sidebar/Sidebar';

import styles from 'src/components/entryPage/EntryPage.module.css';
import LoginHeader from 'src/components/loginPage/LoginHeader';
import easyWithEgain from 'src/components/endInfo/easyWithEgain.svg';
import { m, mergeIf } from 'src/util/cssUtil';
import announcementImg from 'src/img/carousel hand.png';

const SystemHelp = lazy(() =>
  import(
    /* webpackChunkName: "PlanInfo" */
    /* webpackPrefetch: true */
    'src/components/entryPage/systemHelp/SystemHelp'
  )
);

const Marketing = lazy(() =>
  import(
    /* webpackChunkName: "Marketing" */
    /* webpackPrefetch: true */
    'src/components/marketing/Marketing'
  )
);

export default function EntryPage({ children, mode }) {
  const { t } = useTranslation();
  const tenantType = useSelector(journeySlice.select.tenantType);

  return (
    <div className={m(styles.mainBox, 'd-flex', 'flex-column')}>
      {/* {mode === 'login' && <LoginHeader />} */}
{/* 
      <div className={m(styles.announcements)}>
        <div className={m(styles.announcementContainer)}>
          <div className={styles.announcementTextContainer}>
            <p className={styles.announcementTitle}>{t('announcement_title')}</p>
            <p className={styles.announcementContent}>
              {t('announcement_content')}
            </p>
          </div>
          <div className={styles.announcementImgContainer}>
            <img
              src={announcementImg}
              alt="announcementt"
              className={styles.announcementsLogo}
            />
          </div>
        </div>
      </div> */}

      {/* {mode !== 'login' && (
        <div className={m(styles.marginX, 'd-flex')}>
          <div className={styles.primaryBox} style={{ width: '100%' }}>
            <div
              className={mergeIf(
                styles.containerCommon,
                styles.containerStart,
                styles.trialStart,
                'mb-3',
                'ps-5',
                'd-flex',
                'flex-column',
                'justify-content-center',
                'flex-grow-1'
              )}
            >
              <Suspense>
                <SystemHelp mode={mode} />
              </Suspense>
            </div>
          </div>

          <Sidebar />
        </div>
      )} */}

      <main
        className={mergeIf(styles.marginX, 'd-flex', {
          [styles.loginPrimaryBox]: mode === 'login'
        })}
      >
        <div className={mergeIf(styles.primaryBox)} style={{ width: '56%' }}>
          <Container
            className={m(
              styles.containerCommon,
              styles.contentContainer,
              // 'mb-5',
              'd-flex',
              'flex-column',
              'flex-grow-1'
            )}
            style={{background: 'white'}}  
          >
            <div className={styles.containerWrapper} >
              <Suspense>{children}</Suspense>
            </div>
          </Container>
        </div>

        {/* <Sidebar>
          <Suspense>
            <Marketing src={t('marketing_url_1')} title="Marketing content 0" />
          </Suspense>
        </Sidebar> */}
      </main>
      {/* <div className={m(styles.easyFooter)}>
        <img
          src={easyWithEgain}
          className={styles.easyLogo}
          alt="easy with eGain"
        />
      </div> */}
    </div>
  );
}

EntryPage.propTypes = {
  children: PropTypes.element.isRequired,
  mode: PropTypes.string
};
